import _stripAnsi from "strip-ansi";
import _codePointAt from "code-point-at";
import _isFullwidthCodePoint from "is-fullwidth-code-point";
var exports = {};
var stripAnsi = _stripAnsi;
var codePointAt = _codePointAt;
var isFullwidthCodePoint = _isFullwidthCodePoint; // https://github.com/nodejs/io.js/blob/cff7300a578be1b10001f2d967aaedc88aee6402/lib/readline.js#L1345

exports = function (str) {
  if (typeof str !== "string" || str.length === 0) {
    return 0;
  }

  var width = 0;
  str = stripAnsi(str);

  for (var i = 0; i < str.length; i++) {
    var code = codePointAt(str, i); // ignore control characters

    if (code <= 31 || code >= 127 && code <= 159) {
      continue;
    } // surrogates


    if (code >= 65536) {
      i++;
    }

    if (isFullwidthCodePoint(code)) {
      width += 2;
    } else {
      width++;
    }
  }

  return width;
};

export default exports;